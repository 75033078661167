import { formatLineType } from "common";
import React from "react";
import { usePickSlip } from "./create-pick";
import Button from "../primitives/button";
import Icon from "../primitives/icon";
import { usePick } from "@/utils/providers/pick";

export enum PickPurchaseType {
  Activity,
  Featured,
  Profile,
}

export default function PickPurchaseButton({
  type,
}: {
  type: PickPurchaseType;
}) {
  const { addPick } = usePickSlip();
  const { pick, purchased } = usePick();

  console.log("pick purchased", purchased);

  const gameStarted: boolean = new Date(pick.game.date_time_utc) <= new Date();

  if (!pick.sale_price) return null;
  if (gameStarted) return null;

  switch (type) {
    case PickPurchaseType.Featured:
      case PickPurchaseType.Profile:
      if (pick.is_self) {
        return (
          <div className="text-label-lg-bold uppercase text-primary-500 h-8 flex items-center justify-center">
            For Sale ${pick.sale_price / 100}
          </div>
        );
      }
      if (purchased) {
        return (
          <div className="text-label-lg-bold uppercase text-primary-500 h-8 flex items-center justify-center">
            Purchased ${pick.sale_price / 100}
          </div>
        );
      }
      return (
        <div
          className="flex flex-row gap-4 items-center justify-center mt-1 relative z-[3]"
        >
          <Button
            size="xs"
            data-prevent-nprogress={true}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.nativeEvent) {
                e.nativeEvent.stopImmediatePropagation();
              }
              addPick(pick, null, true);
            }}
          >
            Buy Now ${pick.sale_price / 100}
          </Button>
        </div>
      );
    case PickPurchaseType.Activity:
      // Don't render this button if the pick has been revealed already.
      if (pick.is_self) {
        return (
          <div className="text-label-lg-bold uppercase text-primary-300 h-8 flex items-center justify-center">
            For Sale ${pick.sale_price / 100}
          </div>
        );
      }
      if (purchased) {
        return (
          <div className="text-label-lg-bold uppercase text-primary-300 h-8 flex items-center justify-center">
            Purchased ${pick.sale_price / 100}
          </div>
        );
      }
      if (pick.type !== null) return null;
      return (
        <div
          className="flex flex-row gap-4 items-center mt-1 relative z-[3] pr-4"
        >
          <Button
            size="sm"
            data-prevent-nprogress={true}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.nativeEvent) {
                e.nativeEvent.stopImmediatePropagation();
              }
              addPick(pick, null, true);
            }}
          >
            <div className="flex flex-row gap-4 items-center">

            <span>{formatLineType(pick.line, false, true)}</span>
            <span className="flex items-center gap-0.5">
              ${pick.sale_price / 100}{" "}
              <Icon name="shopping-cart-add" size="sm" />
            </span>
            </div>
          </Button>
        </div>
      );
  }
}
