"use client";
import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import Icon from "./icon";
import { ButtonHTMLAttributes } from "react";

export interface RadioGroupProps extends RadixRadioGroup.RadioGroupProps {
  label: string;
  items: RadioGroupItem[];
}

interface RadioGroupItem {
  id: string;
  label?: JSX.Element;
  value: string;
  team?: string;
}

function RadioGroup(props: RadioGroupProps) {
  return (
    <RadixRadioGroup.Root
      className="flex flex-col gap-2.5 w-full"
      aria-label={props.label}
      {...props}
    >
      {props.items.map((item, i) => (
        <>
          <RadixRadioGroup.Item
            key={i} className={`group flex gap-4 h-[4.625rem] items-center w-full p-4 rounded-lg border border-surface-500 focus:border-primary hover:cursor-pointer hover:border-primary data-[state=checked]:border-primary`}
            value={item.value}
            id={item.id}
          >
            <div className="flex w-4 h-4 p-0.5 rounded-full bg-transparent border-[2px] border-surface-500 group-data-[state=checked]:border-primary">
              <RadixRadioGroup.Indicator className="flex w-full h-full bg-primary-300 rounded-full" />
            </div>
            <label
              className="group-hover:cursor-pointer flex flex-row items-center justify-start text-title-lg-medium w-full"
              htmlFor={item.id}
            >
              {item.label}
            </label>
          </RadixRadioGroup.Item>
        </>
      ))}
    </RadixRadioGroup.Root>
  );
}

interface RadioButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  children?: React.ReactNode;
}
function Button(props: RadioButtonProps) {
  return (
    <button className="flex gap-4 h-[4.625rem] items-center w-full p-4 rounded-lg border border-surface-500 hover:border-dark">
      {props.icon && (
        <Icon name={props.icon} size="sm" className="fill-dark" />
      )}
      <span className="text-title-lg-medium">
      {props.children}

      </span>
    </button>
  );
}

RadioGroup.Button = Button;
export default RadioGroup;