import { usePickSlip } from "@/components/pick/create-pick";
import assert from "assert";
import { GameAlignmentType, PickType, RecentPick } from "common";
import React, { createContext, useContext, useState } from "react";

interface PickContextType {
  pick: RecentPick;
  type: PickType | null;
  setType: React.Dispatch<React.SetStateAction<PickType | null>>;
  purchased: boolean;
  setPurchased: (purchased: boolean) => void;
}

const PickContext = createContext<PickContextType | null>(null);

export const usePick = () => {
  const context = useContext(PickContext);
  if (!context) {
    throw new Error("usePick must be used within a PickProvider");
  }
  return context;
};

export const PickProvider = ({
  pick,
  children,
}: {
  pick: RecentPick;
  children: React.ReactNode;
}) => {
    const { justPurchasedPicks, pickSlipPicks } = usePickSlip();
  const [type, setType] = useState<PickType | null>(pick.type);
  const [purchased, setPurchased] = useState<boolean>(pick.purchased);

  React.useEffect(() => {
    if (!justPurchasedPicks) return;
    const purchasedPick = justPurchasedPicks[pick.id];
    if (!purchasedPick) return;
    assert(purchasedPick.type !== null);
    setPurchased(true);
    setType(purchasedPick.type);
  }, [justPurchasedPicks]);

  React.useEffect(() => {
    setType(pick.type);
  }, [pick.type]);

  return (
    <PickContext.Provider
      value={{
        pick,
        type,
        setType,
        purchased,
        setPurchased,
      }}
    >
      {children}
    </PickContext.Provider>
  );
};
