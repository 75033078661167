"use client";
import * as RadixAccordion from "@radix-ui/react-accordion";
import Icon from "./icon";
import Card from "./card";
import Button, { BUTTON_VARIANCE } from "./button";

export type AccordionCombinedProps =
  | (RadixAccordion.AccordionSingleProps & AccordionProps)
  | (RadixAccordion.AccordionMultipleProps & AccordionProps);

type AccordionVariant = "info" | "form" | "card";

interface AccordionProps {
  variant: AccordionVariant;
  items: AccordionItem[];
}

export interface AccordionItem extends Option {
  content: JSX.Element;
}

function Accordion(props: AccordionCombinedProps) {
  if (props.variant === "info") {
    return (
      <RadixAccordion.Root {...props}>
        {props.items.map((item, i) => (
          <div key={i}>
            <RadixAccordion.Item value={item.value}>
              <RadixAccordion.Header>
                <RadixAccordion.Trigger className="group w-full" asChild>
                  <div className="flex justify-between items-center">
                    <p className="text-lg-medium text-left mr-4">
                      {item.label}
                    </p>
                    <Button
                      variant="circle"
                      mode="filled"
                      color="dark"
                      className="ml-4 group-data-[state=open]:bg-light group-data-[state=open]:fill-dark border-[1px] border-dark"
                    >
                      <Icon
                        name="add"
                        size="sm"
                        className="transition-all duration-400 ease-in-out group-data-[state=open]:rotate-180 group-data-[state=open]:opacity-0"
                      ></Icon>
                      <Icon
                        name="subtract"
                        size="sm"
                        className="absolute opacity-0 invisible transition-all duration-400 ease-in-out group-data-[state=open]:rotate-180 group-data-[state=open]:opacity-100 group-data-[state=open]:visible"
                      ></Icon>
                    </Button>
                  </div>
                </RadixAccordion.Trigger>
              </RadixAccordion.Header>

              <RadixAccordion.Content className="mt-4 mr-12">
                {item.content}
              </RadixAccordion.Content>
            </RadixAccordion.Item>
            <hr className="my-8 bg-secondary-700" />
          </div>
        ))}
      </RadixAccordion.Root>
    );
  } else if (props.variant === "card") {
    return (
      <RadixAccordion.Root {...props} className="flex flex-col gap-4 md:gap-6">
        {props.items.map((item, i) => (
          <>
            <RadixAccordion.Item key={i} value={item.value}>
              <RadixAccordion.Header>
                <RadixAccordion.Trigger className="group w-full" asChild>
                  <div>
                  <Card>
                    <div className="flex justify-between items-center">
                      <p className="text-lg-medium text-left mr-4">
                        {item.label}
                      </p>
                      <Button
                        variant="circle"
                        mode="filled"
                        color="dark"
                        className="ml-4 group-data-[state=open]:bg-light group-data-[state=open]:fill-dark border-[1px] border-dark"
                      >
                        <Icon
                          name="add"
                          size="sm"
                          className="transition-all duration-400 ease-in-out group-data-[state=open]:rotate-180 group-data-[state=open]:opacity-0"
                        ></Icon>
                        <Icon
                          name="subtract"
                          size="sm"
                          className="absolute opacity-0 invisible transition-all duration-400 ease-in-out group-data-[state=open]:rotate-180 group-data-[state=open]:opacity-100 group-data-[state=open]:visible"
                        ></Icon>
                      </Button>
                    </div>
                  </Card>
                  </div>
                </RadixAccordion.Trigger>
              </RadixAccordion.Header>

              <RadixAccordion.Content className="bg-white p-4 md:p-6 md:pb-8 -mt-3 rounded-b-lg">
                {item.content}
              </RadixAccordion.Content>
            </RadixAccordion.Item>
          </>
        ))}
      </RadixAccordion.Root>
    );
  } else {
    return (
      <RadixAccordion.Root {...props}>
        {props.items.map((item, i) => (
          <RadixAccordion.Item key={i} value={item.value}>
            <RadixAccordion.Header>
              <RadixAccordion.Trigger
                asChild
                className={`group text-title-medium py-4 hover:cursor-pointer !px-0 hover:opacity-75 uppercase w-full`}
              >
                <div className="flex flex-row items-center gap-2">
                  <Icon
                    name="caret-right"
                    size="md"
                    className="transition-transform duration-200 ease-in-out group-data-[state=open]:rotate-90"
                    aria-hidden
                  />
                  {item.label}
                </div>
              </RadixAccordion.Trigger>
            </RadixAccordion.Header>
            <RadixAccordion.Content>{item.content}</RadixAccordion.Content>
          </RadixAccordion.Item>
        ))}
      </RadixAccordion.Root>
    );
  }
}

// function Content(props: RadixAccordion.AccordionContentProps) {
//   return (
//     <RadixAccordion.Content {...props}>{props.children}</RadixAccordion.Content>
//   );
// }

// Accordion.Content = Content;
export default Accordion;
