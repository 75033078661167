import React from 'react'

export default function CardBrand({brand}: {brand: string}) {
    const url: string = `/logos/${brand}.webp`;
  return (
    <div>
        <img src={url} alt={`${brand} Card Logo`} />
    </div>
  )
}
