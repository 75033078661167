"use client";
import {
  Action,
  buildOdd,
  buildPaymentMethod,
  CheckoutIntent,
  GameAlignmentType,
  GameOdds,
  LineType,
  MarketplaceActionPayload,
  MarketplaceActionType,
  PaymentMethod,
  RecentPick as RecentPickType,
} from "common";
import React, { createContext, useContext } from "react";
import RecentPick, { RecentPickState } from "../bettor/recent-pick";
import {
  loadStripe,
  StripeElementsOptionsMode,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import mlClient from "@/ml-client";
import {
  Elements,
  ExpressCheckoutElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useAuth } from "@clerk/clerk-react";
import Link from "next/link";
import Button from "../primitives/button";
import { PickSlipContextType, usePickSlip } from "./create-pick";
import assert from "assert";
import CreatedPick from "./created";
import PickSportsbooks, { getSportsbookEvents } from "./sportsbooks";
import { LoadingCircle } from "../primitives/loading";
import { useScoreboard } from "@/utils/providers/scoreboard";
import Icon from "../primitives/icon";
import { useOddsFormat } from "@/utils/providers/odds-format";
import { formatOdd } from "@/utils";
import RadioGroup from "../primitives/radio-group";
import Checkbox from "../primitives/checkbox";
import CardBrand from "../primitives/card-brand";
import Info from "../primitives/info";
import Accordion, { AccordionItem } from "../primitives/accordion";
import { PickProvider } from "@/utils/providers/pick";
const stripePromise = loadStripe("redacted");

export interface PickCheckoutContextType {
  checkoutIntent: CheckoutIntent | null;
  setCheckoutIntent: React.Dispatch<
    React.SetStateAction<CheckoutIntent | null>
  >;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  loading: PickCheckoutStep | null;
  setLoading: React.Dispatch<React.SetStateAction<PickCheckoutStep | null>>;
  step: PickCheckoutStep;
  setStep: React.Dispatch<React.SetStateAction<PickCheckoutStep>>;
  revealedPick: RecentPickType | null;
  setRevealedPick: React.Dispatch<React.SetStateAction<RecentPickType | null>>;
  paymentMethod: PaymentMethod | null;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod | null>>;
}
export const PickCheckoutContext =
  createContext<PickCheckoutContextType | null>(null);

export const usePickCheckout = () => {
  const context = useContext(PickCheckoutContext);
  if (!context) {
    throw new Error(
      "usePickCheckout must be used within a PickCheckoutProvider"
    );
  }
  return context;
};

enum PickCheckoutStep {
  Overview,
  Payment,
  Review,
  Reveal,
}

export default function PickCheckout() {
  const slip = usePickSlip();
  const [checkoutIntent, setCheckoutIntent] =
    React.useState<CheckoutIntent | null>(null);
  const [loading, setLoading] = React.useState<PickCheckoutStep | null>(
    PickCheckoutStep.Overview
  );
  const [step, setStep] = React.useState<PickCheckoutStep>(
    PickCheckoutStep.Overview
  );
  const [error, setError] = React.useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [revealedPick, setRevealedPick] = React.useState<RecentPickType | null>(
    null
  );
  // Change button whenever the step or loading state changes.
  React.useEffect(() => {
    if (error) {
      slip.setButton(
        <Button
          className="!justify-center min-w-[146px]"
          onClick={() => slip.removeCheckoutPick()}
        >
          Cancel
        </Button>
      );
    }
    if (loading !== null) {
      slip.setButton(
        <Button className="!justify-center min-w-[146px]" disabled={true}>
          Loading...
        </Button>
      );
    }
  }, [loading]);

  assert(slip?.checkoutPick, "Pick isn't for sale.");

  const options: StripeElementsOptionsMode = {
    mode: "payment",
    amount: (checkoutIntent?.amount || 0) + (checkoutIntent?.tax || 0),
    currency: "usd",
    paymentMethodCreation: "manual",
    appearance: {
      theme: "flat",
    },
  };

  const render = (): React.ReactNode => {
    if (error) {
      return (
        <div className="flex flex-col gap-4 bg-surface-700 py-12 px-4 rounded-md">
          <Info.Block variant="error" title="Sorry Sport!" message={error} />
        </div>
      );
    }
    switch (step) {
      case PickCheckoutStep.Overview:
        return <Overview />;
      case PickCheckoutStep.Payment:
        return (
          <Elements stripe={stripePromise} options={options}>
            <Payment />
          </Elements>
        );
      case PickCheckoutStep.Review:
        return <Review />;
      case PickCheckoutStep.Reveal:
        return <Reveal />;
      default:
        return <>Something rather unexpected happened. Contact an admin.</>;
    }
  };
  return (
    <PickCheckoutContext.Provider
      value={{
        checkoutIntent,
        setCheckoutIntent,
        error,
        setError,
        loading,
        setLoading,
        step,
        setStep,
        paymentMethod,
        setPaymentMethod,
        revealedPick,
        setRevealedPick,
      }}
    >
      <Steps />
      {render()}
    </PickCheckoutContext.Provider>
  );
}

/** Step 1 - Overview
 * Gets or creates a checkout intent.
 */
function Overview() {
  const checkout = usePickCheckout();
  const slip = usePickSlip();
  const { getToken } = useAuth();
  assert(slip?.checkoutPick, "Pick isn't for sale.");

  /** Any time the pick id changes, get the checkout intent.
   * First will attempt to get the intent from localStorage and will fallback to the server.
   */
  const createCheckoutIntent = async () => {
    assert(slip?.checkoutPick, "Pick isn't for sale.");
    assert(slip?.checkoutPick.id, "Pick is missing id.");
    try {
      checkout.setLoading(PickCheckoutStep.Overview);
      const token = await getToken();
      const action: Action<MarketplaceActionType, MarketplaceActionPayload> = {
        type: MarketplaceActionType.CreatePaymentIntent,
        data: {
          pick_id: slip.checkoutPick.id,
          bettor_id: slip.checkoutPick.bettor_id,
        },
      };
      const result = await mlClient.post(
        "/marketplace/checkout-intent",
        action,
        token,
        {
          validateStatus: () => true,
        }
      );
      if (!result.data.ok) {
        checkout.setError(result.data.message);
        return;
      }
      if (result.data.data) {
        const intent = result.data.data;
        checkout.setCheckoutIntent(intent);
        checkout.setRevealedPick({
          ...slip.checkoutPick,
          bettor: intent.metadata.pick.bettor,
          created_at: intent.metadata.pick.created_at,
        });
        // localStorage.setItem(localCheckoutIntentKey, JSON.stringify(intent));
        slip.setPrice(((intent.amount + intent.tax) / 100).toFixed(2));
      }
    } catch (err) {
      console.log("Error in createPaymentIntent:", err);
      checkout.setError("Something unexpected happened. Contact an admin.");
    } finally {
      checkout.setLoading(null);
    }
  };

  React.useEffect(() => {
    if (!checkout.checkoutIntent) {
      createCheckoutIntent();
    }
  }, [checkout.checkoutIntent, slip.checkoutPick.id]);

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button
        className="!justify-center min-w-[146px]"
        onClick={() => {
          checkout.setStep(PickCheckoutStep.Payment);
        }}
        disabled={checkout.error ? true : false}
      >
        Continue
      </Button>
    );
  }, [checkout.loading, checkout.step, checkout.error]);

  if (checkout.loading === PickCheckoutStep.Overview) {
    return (
      <div className="flex flex-col gap-4 bg-surface-700 py-12 px-4 rounded-md">
        <Info.Block
          variant="loading"
          title="Loading..."
          message="Fetching more details."
        />
      </div>
    );
  }
  if (checkout.error) {
    return <>{checkout.error}</>;
  }

  assert(checkout.revealedPick);
  return (
    <div className="flex flex-col gap-4 bg-surface-700 p-4 rounded-md">
      <PickProvider pick={checkout.revealedPick}>
        <RecentPick
          remove={slip.removeCheckoutPick}
          state={RecentPickState.Checkout}
        />
      </PickProvider>
      <OddComparison />
      <Total />
      <div className="text-secondary text-center text-body-sm leading-6 max-w-[280px] mx-auto">
        By completing your purchase, you agree to our{" "}
        <Link href="/terms-conditions" className="underline">
          Terms & Conditions
        </Link>{" "}
        and{" "}
        <Link href="/privacy-policy" className="underline">
          Privacy Policy.
        </Link>
      </div>
    </div>
  );
}

/** Step 2 - Payment
 * Provides a list of saved payment methods and allows you add new ones.
 * Once finished, collects your details to attach it to the intent on the server.
 */
function Payment() {
  const slip = usePickSlip();
  const checkout = usePickCheckout();
  const stripe = useStripe();
  const elements = useElements();
  const { getToken } = useAuth();
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>(
    checkout.checkoutIntent?.payment_methods || []
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<PaymentMethod | null>(() => {
      const selectedId = checkout.checkoutIntent?.selected_payment_method_id;
      let foundMethod = null;
      if (selectedId) {
        foundMethod = paymentMethods.find((pm) => pm.id === selectedId);
      }
      return (
        foundMethod ?? (paymentMethods.length > 0 ? paymentMethods[0] : null)
      );
    });

  const [expressCheckoutReady, setExpressCheckoutReady] =
    React.useState<boolean>(false);
  const [paymentFormComplete, setPaymentFormComplete] =
    React.useState<boolean>(false);
  const [savePaymentMethod, setSavePaymentMethod] =
    React.useState<boolean>(true);

    console.log("saving payment method", savePaymentMethod);

  const changePaymentMethod = (value: string) => {
    const pm: PaymentMethod | undefined = paymentMethods.find(
      (pm) => pm.id === value
    );
    if (!pm) return;
    setSelectedPaymentMethod(pm);
  };

  const deletePaymentMethod = async (paymentMethodId: string) => {
    try {
      const token = await getToken();
      const result = await mlClient.delete(
        `/marketplace/payment-methods/${paymentMethodId}`,
        token
      );
      if (result.data.ok) {
        setPaymentMethods((prev) =>
          prev.filter((pm) => pm.id !== paymentMethodId)
        );
        if (selectedPaymentMethod?.id === paymentMethodId) {
          setSelectedPaymentMethod(null);
        }
      } else {
        checkout.setError("Failed to delete payment method. Try again.");
      }
    } catch (err) {
      console.error("Error deleting payment method:", err);
      checkout.setError("Something unexpected happened. Contact an admin.");
    }
  };

  const updateCheckoutIntent = async () => {
    console.log("updating checkout intent");
    assert(slip?.checkoutPick, "Pick isn't for sale.");
    let usingPaymentMethod: PaymentMethod | null =
      selectedPaymentMethod ?? null;
    try {
      if (!usingPaymentMethod || paymentFormComplete) {
        if (!stripe || !elements) {
          checkout.setError("Stripe not loaded.");
          return;
        }
        const { error: submitError } = await elements.submit();
        if (submitError) {
          console.error("Form validation failed:", submitError.message);
          checkout.setError("Payment element failed to validate.");
          return;
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          elements,
        });

        if (error) {
          console.error("Error creating payment method:", error.message);
          checkout.setError("Error creating payment method.");
          return;
        }
        usingPaymentMethod = buildPaymentMethod(paymentMethod);
        console.log("New payment method created:", paymentMethod);
      }
      assert(usingPaymentMethod !== null, "Failed to collect payment method.");
      checkout.setLoading(PickCheckoutStep.Review);
      const token = await getToken();
      const action = {
        type: MarketplaceActionType.ConfirmPayment,
        data: {
          pick_id: slip.checkoutPick.id,
          selected_payment_method_id: usingPaymentMethod.id,
          save_payment_method: savePaymentMethod,
        },
      };
      const result = await mlClient.put(
        "/marketplace/checkout-intent",
        action,
        token
      );
      if (result.data.ok && result.data.code === 201) {
        const newIntent = checkout.checkoutIntent
          ? {
              ...checkout.checkoutIntent,
              selected_payment_method_id: usingPaymentMethod.id,
            }
          : checkout.checkoutIntent;
        checkout.setCheckoutIntent(newIntent);
        checkout.setPaymentMethod(usingPaymentMethod);
        checkout.setStep(PickCheckoutStep.Review);
      }
    } catch (err) {
      console.error("Error updating checkout intent", err);
      checkout.setError("Something unexpected happened. Contact an admin.");
    } finally {
      checkout.setLoading(null);
    }
  };

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button
        className="!justify-center min-w-[146px]"
        onClick={() => updateCheckoutIntent()}
        disabled={!paymentFormComplete && !selectedPaymentMethod}
      >
        Continue
      </Button>
    );
  }, [checkout.loading, checkout.step, paymentFormComplete]);

  assert(slip?.checkoutPick, "Pick isn't for sale.");

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "auto",
  };

  if (checkout.loading === PickCheckoutStep.Review) {
    return (
      <div className="flex flex-col gap-4 bg-surface-700 py-12 px-4 rounded-md">
        <Info.Block
          variant="loading"
          title="Processing..."
          message="Saving and validating payment methods."
        />
      </div>
    );
  }

  const onConfirm = async () => {
    return;
  };

  if (!stripe || !elements) {
    return;
  }

  const handlePaymentElementChange = (event: any) => {
    setPaymentFormComplete(event.complete);
  };

  console.log("payment form complete", paymentFormComplete);

  let paymentMethodItems = paymentMethods.map((pm, i) => {
    return {
      id: `payment-method-${i}`,
      label: (
        <div className="flex flex-row gap-2 items-center w-full justify-between">

        <div className="flex flex-row items-center gap-2">
          <CardBrand brand={pm.brand} />{" "}
          <span className="text-secondary-500">
            xxxx-<span className="text-secondary-300">{pm.last4}</span>
          </span>{" "}
          <span className="text-secondary-500">
            ({pm.exp_month}/{pm.exp_year})
          </span>
        </div>
        <Button
            variant="circle"
            color="tertiary"
            size="sm"
            onClick={() => deletePaymentMethod(pm.id)}
            aria-label="Delete payment method"
            >
            <Icon name="bin" size="sm" />
          </Button>
            </div>
      ),
      value: pm.id,
    };
  });

  let accordionDefaultValue = "new";
  const accordionItems: AccordionItem[] = [];
  if (paymentMethodItems.length > 0) {
    accordionDefaultValue = "saved";
    accordionItems.push({
      label: "Saved Payment Methods",
      value: "saved",
      content: (
        <>
          <RadioGroup
            value={selectedPaymentMethod?.id}
            onValueChange={changePaymentMethod}
            label="payment-methods"
            items={paymentMethodItems}
          />
        </>
      ),
    });
  }
  accordionItems.push({
    label: "+ Add a new credit or debit card",
    value: "new",
    content: (
      <div className="flex flex-col gap-4">
        <PaymentElement
          id="payment-element"
          options={paymentElementOptions}
          onLoadError={(e) => checkout.setError(e.error.message || "")}
          onChange={handlePaymentElementChange}
        />
        <Checkbox
          id="save-payment-method"
          label="Save this payment method"
          checked={savePaymentMethod}
          onCheckedChange={() => setSavePaymentMethod((prev) => !prev)}
          disabled={!paymentFormComplete}
        />
      </div>
    ),
  });

  return (
    <div className="flex flex-col gap-4 bg-surface-700 p-4 rounded-md">
      {/* <ExpressCheckoutElement
        onConfirm={onConfirm}
        onReady={() => setExpressCheckoutReady(true)}
        onLoadError={(e) => checkout.setError(e.error.message || "")}
      /> */}
      {/* {!expressCheckoutReady && <div>Loading Express Checkout...</div>} */}
      <div className="text-title-lg-bold uppercase">Payment Method</div>
      <div className="text-body-sm text-secondary">
        All transactions are secure and encrypted.<br></br>Choose how you'd like
        to pay.
      </div>
      {/* {paymentMethods.length > 0 && (
        <>
          <div className="flex flex-row justify-between items-center gap-4">
            <div className="h-px w-full bg-secondary-700"></div>
            <div className="text-secondary min-w-[160px] text-center">
              saved payment methods
            </div>
            <div className="h-px w-full bg-secondary-700"></div>
          </div>
          <RadioGroup
            value={selectedPaymentMethod?.id}
            onValueChange={changePaymentMethod}
            label="payment-methods"
            items={paymentMethodItems}
          />
          <div className="flex flex-row justify-between items-center gap-4">
            <div className="h-px w-full bg-secondary-700"></div>
            <div className="text-secondary min-w-[140px] text-center">
              or create a new one
            </div>
            <div className="h-px w-full bg-secondary-700"></div>
          </div>
        </>
      )} */}
      <Accordion
        defaultValue={accordionDefaultValue}
        type="single"
        variant="form"
        items={accordionItems}
      />
      {/* <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onLoadError={(e) => checkout.setError(e.error.message || "")}
        onChange={handlePaymentElementChange}
      />
      <Checkbox
        id="save-payment-method"
        label="Save this payment method"
        checked={savePaymentMethod}
        onCheckedChange={() => setSavePaymentMethod((prev) => !prev)}
        disabled={!paymentFormComplete}
      /> */}
      <Total />
    </div>
  );
}

/** Step 3 - Review
 * Shows a summary of the payment to be made
 * along with the selected payment method.
 */
function Review() {
  const slip = usePickSlip();
  const checkout = usePickCheckout();
  const { getToken } = useAuth();

  const confirmPayment = async () => {
    assert(slip?.checkoutPick, "Pick isn't for sale.");
    try {
      checkout.setLoading(PickCheckoutStep.Reveal);
      const token = await getToken();
      const action = {
        type: MarketplaceActionType.ConfirmPayment,
        data: {
          pick_id: slip.checkoutPick.id,
          bettor_id: slip.checkoutPick.bettor_id,
        },
      };
      const result = await mlClient.post(
        "/marketplace/confirm-payment",
        action,
        token,
        {
          validateStatus: () => true,
        }
      );
      if (!result.data.ok) {
        console.error(result.data.message);
        checkout.setError(result.data.message);
        return;
      }
      if (result.data.ok && result.data.code === 201) {
        checkout.setCheckoutIntent(result.data.data);
        console.log("checkout intent data", result.data.data);
        const newRevealedPick = checkout.revealedPick
          ? { ...checkout.revealedPick, type: result.data.data.revealed_pick }
          : null;
        checkout.setRevealedPick(newRevealedPick);
        localStorage.removeItem("checkoutPick");
        if (newRevealedPick) {
          slip.setJustPurchasedPicks((prev) => ({
            ...(prev || {}),
            [newRevealedPick.id]: { type: newRevealedPick.type },
          }));
          const sportsbookEvents = await getSportsbookEvents([newRevealedPick]);
          slip.setSportsbooks(sportsbookEvents);
        }
        checkout.setStep(PickCheckoutStep.Reveal);
      }
    } catch (err) {
      console.error("Error confirming payment.", err);
      checkout.setError("Something unexpected happened. Contact an admin.");
    } finally {
      checkout.setLoading(null);
    }
  };

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button
        className="!justify-center min-w-[146px]"
        onClick={() => confirmPayment()}
      >
        Confirm & Pay
      </Button>
    );
  }, [checkout.loading, checkout.step]);

  if (checkout.loading === PickCheckoutStep.Reveal) {
    return (
      <div className="flex flex-col gap-4 bg-surface-700 py-12 px-4 rounded-md">
        <Info.Block
          variant="loading"
          title="Processing..."
          message="Get ready to see your pick!"
        />
      </div>
    );
  }

  assert(slip?.checkoutPick, "Pick isn't for sale.");
  assert(checkout.revealedPick);
  assert(checkout.paymentMethod);
  return (
    <div className="flex flex-col gap-4 bg-surface-700 p-4 rounded-md">
      <PickProvider pick={checkout.revealedPick}>
        <RecentPick
          remove={slip.removeCheckoutPick}
          state={RecentPickState.Checkout}
        />
      </PickProvider>
      <OddComparison />
      <div className="text-title-lg-bold uppercase mt-2">Payment Summary</div>
      <div className="text-body-sm text-secondary">
        Make sure your information is correct.
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-2">
          <CardBrand brand={checkout.paymentMethod.brand} />{" "}
          <span className="text-secondary-500">
            xxxx-
            <span className="text-secondary-300">
              {checkout.paymentMethod.last4}
            </span>
          </span>{" "}
          <span className="text-secondary-500">
            ({checkout.paymentMethod.exp_month}/
            {checkout.paymentMethod.exp_year})
          </span>
        </div>
        <button
          className="link text-label-lg-bold text-secondary-300 uppercase"
          onClick={() => checkout.setStep(PickCheckoutStep.Payment)}
        >
          Change
        </button>
      </div>
      <Total />
    </div>
  );
}

function Reveal() {
  const slip = usePickSlip();
  const checkout = usePickCheckout();
  assert(slip.checkoutPick, "Must have a pick in cart.");

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button className="!justify-center min-w-[146px]" onClick={() => {
        slip.setSlipOpen(false);
        slip.removeCheckoutPick();
      }}>Done</Button>
    );
  }, [checkout.loading, checkout.step]);

  console.log("revealed", checkout.revealedPick);
  assert(checkout.revealedPick);

  return (
    <div className="gap-6 grid grid-rows-[auto_auto] px-2 sm:px-3 pb-4 overflow-y-auto grow mt-2">
      <div className="flex flex-col gap-4">
        <CreatedPick pick={checkout.revealedPick} purchased />
      </div>
      <PickSportsbooks
        sportsbooks={slip.sportsbooks}
        activePick={checkout.revealedPick}
      />
    </div>
  );
}

function Steps() {
  const checkout = usePickCheckout();
  const disabled: boolean =
    checkout.error !== null || checkout.loading !== null;
  return (
    <div className="flex flex-row items-center gap-4 px-4 pb-4">
      <button
        className={`${
          checkout.step >= PickCheckoutStep.Overview
            ? "text-secondary-200"
            : "text-secondary-500"
        } transition-all ease-in-out duration-200 text-title-lg-bold uppercase`}
        onClick={() => {
          if (checkout.step >= PickCheckoutStep.Overview) {
            checkout.setStep(PickCheckoutStep.Overview);
          }
        }}
        disabled={disabled}
      >
        Overview
      </button>
      <div
        className={`${
          checkout.step > PickCheckoutStep.Overview
            ? "bg-secondary-200"
            : "bg-secondary-700"
        } transition-all ease-in-out duration-200 w-full h-px`}
      ></div>
      <button
        className={`${
          checkout.step >= PickCheckoutStep.Payment
            ? "text-secondary-200"
            : "text-secondary-500"
        } transition-all ease-in-out duration-200 text-title-lg-bold uppercase`}
        onClick={() => {
          if (checkout.step >= PickCheckoutStep.Overview) {
            checkout.setStep(PickCheckoutStep.Payment);
          }
        }}
        disabled={disabled}
      >
        Payment
      </button>
      <div
        className={`${
          checkout.step > PickCheckoutStep.Payment
            ? "bg-secondary-200"
            : "bg-secondary-700"
        } transition-all ease-in-out duration-200 w-full h-px`}
      ></div>
      <button
        className={`${
          checkout.step >= PickCheckoutStep.Review
            ? "text-secondary-200"
            : "text-secondary-500"
        } transition-all ease-in-out duration-200 text-title-lg-bold uppercase`}
        onClick={() => {
          if (
            checkout.step >= PickCheckoutStep.Review ||
            checkout.paymentMethod
          ) {
            checkout.setStep(PickCheckoutStep.Review);
          }
        }}
        disabled={disabled}
      >
        Review
      </button>
    </div>
  );
}

function Total() {
  const checkout = usePickCheckout();
  const subtotal = ((checkout.checkoutIntent?.amount || 100) / 100).toFixed(2);
  const tax = (checkout.checkoutIntent?.tax || 0).toFixed(2);
  const total = (
    (checkout.checkoutIntent?.amount || 100) / 100 +
    (checkout.checkoutIntent?.tax || 0)
  ).toFixed(2);
  const currency = "USD";
  return (
    <div className="w-full bg-surface-500 rounded-md flex flex-col p-3 gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row text-title-sm justify-between items-center">
          <span>Subtotal</span>
          <span>${subtotal}</span>
        </div>
        <div className="flex flex-row text-title-sm justify-between items-center">
          <span>Tax</span>
          <span>${tax}</span>
        </div>
      </div>
      <div className="flex flex-row text-title-lg-bold uppercase justify-between items-center">
        <span>Total</span>
        <span>
          {currency} ${total}
        </span>
      </div>
    </div>
  );
}

function OddComparison() {
  const slip = usePickSlip();
  const checkout = usePickCheckout();
  const { oddsFormat } = useOddsFormat();
  assert(slip.checkoutPick);

  let sameOdds: boolean = false;
  const odds: GameOdds | undefined = checkout.checkoutIntent?.metadata.odds;
  assert(odds, "Checkout intent didn't deliver game odds.");
  const awayOdds = buildOdd(
    slip.checkoutPick.line,
    GameAlignmentType.Away,
    odds
  );
  const homeOdds = buildOdd(
    slip.checkoutPick.line,
    GameAlignmentType.Home,
    odds
  );
  const formattedAwayOdds = formatOdd(oddsFormat, awayOdds);
  const formattedHomeOdds = formatOdd(oddsFormat, homeOdds);
  switch (slip.checkoutPick.line) {
    case LineType.Moneyline:
      if (
        slip.checkoutPick.odds[0].payout === odds.awayMoneyline &&
        slip.checkoutPick.odds[1].payout === odds.homeMoneyline
      ) {
        sameOdds = true;
      }
      break;
    case LineType.Spread:
      if (
        slip.checkoutPick.odds[0].value === odds.awaySpread &&
        slip.checkoutPick.odds[1].value === odds.homeSpread &&
        slip.checkoutPick.odds[0].payout === odds.awaySpreadPayout &&
        slip.checkoutPick.odds[1].payout === odds.homeSpreadPayout
      ) {
        sameOdds = true;
      }
      break;
    case LineType.Total:
      if (
        slip.checkoutPick.odds[0].value === odds.overUnder &&
        slip.checkoutPick.odds[0].payout === odds.overPayout &&
        slip.checkoutPick.odds[1].payout === odds.underPayout
      ) {
        sameOdds = true;
      }
      break;
  }
  return (
    <div className="bg-secondary-300 rounded-md p-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-y-1">
          <div className="text-label-lg-bold uppercase text-white">
            Current Game Odds
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <Icon
              variant="circle"
              name={sameOdds ? "checkmark" : "close"}
              size="xs"
              className={`${
                sameOdds ? "bg-primary-300" : "bg-error-400"
              } fill-surface-700`}
            />
            <div className="text-label-sm-medium text-secondary">
              Odds are currently {sameOdds ? "the same." : "different"}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-3 justify-between items-center text-label-lg-bold">
            <span className="text-surface-700 uppercase min-w-[24px]">
              {slip.checkoutPick.game.teams[0].abbreviation}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedAwayOdds?.value}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedAwayOdds?.payout}
            </span>
          </div>
          <div className="flex flex-row gap-x-3 justify-between items-center text-label-lg-bold">
            <span className="text-surface-700 uppercase min-w-[24px]">
              {slip.checkoutPick.game.teams[1].abbreviation}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedHomeOdds?.value}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedHomeOdds?.payout}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
