import React from "react";

export default function PickSlipTriggerContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="absolute min-h-screen min-w-screen inset-0 pointer-events-none">{children}</div>
  );
}
